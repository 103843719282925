import store from "@/store";
import service from "@/api/admin/profile";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import tools from "@/utils/tools";
export default defineComponent({
  setup() {
    const state = reactive({
      user: {
        userName: "",
        nickName: ""
      },
      password: {
        oldPassword: "",
        newPassword: ""
      },
      confirmPassword: ""
    });
    const mothods = {
      updateName() {
        service.updateName(state.user).then(res => {
          tools.message("修改成功", "success");
          store.state.userInfo = state.user;
        });
      },

      updatePassword() {
        if (state.password.oldPassword === "" || state.password.newPassword === "") {
          tools.message("输入不能为空", "warning");
          return;
        }

        if (state.confirmPassword !== state.password.newPassword) {
          tools.message("确认密码与密码不一致!", "warning");
          return;
        }

        service.updatePassword(state.password).then(res => {
          tools.message("修改密码成功", "success");
        });
      }

    };
    onMounted(() => {
      state.user = store.state.userInfo;
    });
    return { ...toRefs(state),
      ...mothods
    };
  }

});