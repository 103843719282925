import request from "@/utils/system/request";

export interface AccountInfo {
  userName: string;
  nickName: string;
}

export interface UpdatePasswrodDto {
  oldPassword: string;
  newPassword: string;
}

export default {
  updateName(data: AccountInfo) {
    return request({
      url: "admin/profile/UpdateName",
      method: "post",
      data
    })
  },
  updatePassword(data: UpdatePasswrodDto) {
    return request({
      url: "admin/profile/UpdatePassword",
      method: "post",
      data
    })
  }
}
